import React, { useState, useEffect } from 'react';
import './index.scss'
import { get } from '../../util/request';
import { useTranslation } from 'react-i18next';
import { userInfo } from '../../redux/slice';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { post } from '../../util/request';
import {
  i18n
} from 'i18next';
import Loading from '../../components/common/loading';
import { set } from 'lodash';
import SubmitSuccessPopUp from '../../components/page/submitSuccessPopUp';
import { useHistory } from 'react-router';


const TaxQuestionnaire = () => {
  const { t } = useTranslation();
  const user = useSelector(userInfo);
  const [content, setContent] = React.useState('');
  const [model, setModel] = useState({})
  const [isTaxFillingAvailable, setIsTaxFillingAvailable] = useState(false)
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  function goDetail(url) {
    history.push({
      pathname: url
    });
  }

  let params = {
    accessToken: user.accessToken,
    userId: user.userId,
  }
  const getQuestionnaire = async () => {
    await get('content/taxQuestionnaire', params).then((data) => {
      setModel(data[0])
      getContent(data[0]);
    })
  }

  const checkIfEligibleForTaxFillingQuestionnaire = async () => {
    await get('content/isTaxFillingQuestionnaireAvailable', params).then((data) => {
      if (data) {
        setIsTaxFillingAvailable(data);
      }
      else {
        goDetail('/home');
      }
    })
  }

  const handleOk = () => {
    setIsModalOpen(false);
    if (!isError) {
      goDetail('/home');
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.type !== "textarea") {
      event.preventDefault();
    }
  };


  const handleSubmit = async (event) => {

    console.log(event.key); 
    event.preventDefault()
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    formProps['accessToken'] = user.accessToken;
    formProps['userId'] = user.userId;
    setLoading(true);
    await post('/content/taxQuestionnaire', formProps).then((data) => {
      if (data.message === 'Success') {
        setIsModalOpen(true);
        setLoading(false);
        setIsError(false);
      }
    }).catch(() => {
      setIsModalOpen(false);
      setLoading(false);
      setIsError(true);
    });
  };

  useEffect(() => {
    checkIfEligibleForTaxFillingQuestionnaire();
  }, []);

  useEffect(() => {
    if (isTaxFillingAvailable) {
      getQuestionnaire();
    }
  }, [isTaxFillingAvailable])

  useEffect(() => {
    if (content !== '') {
      setLoading(false);
    }
  }, [content])

  React.useEffect(() => {
    if (Object.keys(model).length > 0) {
      getContent(model);
    }
  }, [localStorage['lang']]);

  const getContent = (model) => {
    switch (localStorage['lang']) {
      case 'zh_HK':
        return setContent(model.contentSC)
      case 'zh_CN':
        return setContent(model.contentTC)
      case 'jp':
        return setContent(model.contentJA)
      default:
        return setContent(model.contentEN)
    }
  }

  return (
    <div className='tax-questionnaire' id='tax-questionnaire-id'>
      {loading ? <Loading /> : null}
      {
        content !== '' ? (<>
          <SubmitSuccessPopUp isModalOpen={isModalOpen} title={isError ? t('SUBMISSION ERROR') : t('submissionSuccessful')} content={isError ? t('Submission error. Please try again.') : t('submissionSuccessful')} handleOk={handleOk} handleCancel={handleOk} icon={isError ? 'submit-error' : 'submit-success'} />
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {isTaxFillingAvailable ? <input type='submit' value={t('Submit')} className='submit-button' /> : null}
          </form>
        </>) : null
      }
    </div>
  );
};
export default TaxQuestionnaire;
